import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const SSyugoTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "作業者", value: "worker" },
  { text: "0.32TA本数", value: "ta0p32" },
  { text: "仕上がり条長(m)", value: "finishLength" },
  { text: "仕上数", value: "finishNum" },
  { text: "5.5(70/0.32TA)(m)", value: "meter5p5" },
  { text: "仕上数", value: "finishNum2" },
  { text: "3.5(45/0.32TA)(m)", value: "meter3p5" },
  { text: "仕上数", value: "finishNum3" },
  { text: "その他(構成)", value: "otherStructure" },
  { text: "仕上がり条長(m)", value: "finishLength2" },
  { text: "仕上数", value: "finishNum4" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class SSyugoTable {
  workDate: string;
  updateTs: string | null;
  worker: string | null;
  ta0p32: string | null;
  finishLength: string | null;
  finishNum: number | null;
  meter5p5: number | null;
  finishNum2: number | null;
  meter3p5: number | null;
  finishNum3: number | null;
  otherStructure: string | null;
  finishLength2: number | null;
  finishNum4: number | null;
  abnormality: string | null;
  biko: string | null;

  constructor(props: {
    workDate: string;
    worker: string | null;
    ta0p32: string | null;
    finishLength: string | null;
    finishNum: number | null;
    meter5p5: number | null;
    finishNum2: number | null;
    meter3p5: number | null;
    finishNum3: number | null;
    otherStructure: string | null;
    finishLength2: number | null;
    finishNum4: number | null;
    abnormality: string | null;
    biko: string | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.worker = props.worker;
    this.ta0p32 = props.ta0p32;
    this.finishLength = props.finishLength;
    this.finishNum = props.finishNum;
    this.meter5p5 = props.meter5p5;
    this.finishNum2 = props.finishNum2;
    this.meter3p5 = props.meter3p5;
    this.finishNum3 = props.finishNum3;
    this.otherStructure = props.otherStructure;
    this.finishLength2 = props.finishLength2;
    this.finishNum4 = props.finishNum4;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
  }
}

export const SSyugoText = {
  workDate: { text: "作業年月日", value: "workDate" },
  worker: { text: "作業者", value: "worker" },
  ta0p32: { text: "0.32TA本数", value: "ta0p32" },
  finishLength: { text: "仕上がり条長(m)", value: "finishLength" },
  finishNum: { text: "仕上数", value: "finishNum" },
  meter5p5: { text: "5.5(70/0.32TA)(m)", value: "meter5p5" },
  finishNum2: { text: "仕上数", value: "finishNum2" },
  meter3p5: { text: "3.5(45/0.32TA)(m)", value: "meter3p5" },
  finishNum3: { text: "仕上数", value: "finishNum3" },
  otherStructure: { text: "その他(構成)", value: "otherStructure" },
  finishLength2: { text: "仕上がり条長(m)", value: "finishLength2" },
  finishNum4: { text: "仕上数", value: "finishNum4" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class SSyugo extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  worker: string | null;
  ta0p32: string | null;
  finishLength: string | null;
  finishNum: number | null;
  meter5p5: number | null;
  finishNum2: number | null;
  meter3p5: number | null;
  finishNum3: number | null;
  otherStructure: string | null;
  finishLength2: number | null;
  finishNum4: number | null;
  abnormality: string | null;
  biko: string | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: SSyugo);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: SSyugo | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof SSyugo) {
      this.postCode = _props.postCode;
      this.worker = _props.worker;
      this.ta0p32 = _props.ta0p32;
      this.finishLength = _props.finishLength;
      this.finishNum = _props.finishNum;
      this.meter5p5 = _props.meter5p5;
      this.finishNum2 = _props.finishNum2;
      this.meter3p5 = _props.meter3p5;
      this.finishNum3 = _props.finishNum3;
      this.otherStructure = _props.otherStructure;
      this.finishLength2 = _props.finishLength2;
      this.finishNum4 = _props.finishNum4;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "SSYUGO";
    this.worker = null;
    this.ta0p32 = null;
    this.finishLength = null;
    this.finishNum = null;
    this.meter5p5 = null;
    this.finishNum2 = null;
    this.meter3p5 = null;
    this.finishNum3 = null;
    this.otherStructure = null;
    this.finishLength2 = null;
    this.finishNum4 = null;
    this.abnormality = null;
    this.biko = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
